import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import StatusContainer from './components/StatusContainer';
import Projects from './components/Projects';
import Footer from './components/Footer';
import './App.css';

function App() {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [jellyfishStyles, setJellyfishStyles] = useState([]);

  useEffect(() => {
    document.documentElement.classList.toggle('light-mode', !isDarkMode);
  }, [isDarkMode]);

  useEffect(() => {
    const generateJellyfishStyles = () => {
      const styles = [];
      for (let i = 0; i < 5; i++) {
        const style = document.createElement('style');
        const animationName = `swim-${i}`;
        const duration = Math.random() * 10 + 15; // 
        const delay = Math.random() * -6; // 0-6 seconds delay

        style.textContent = `
          @keyframes ${animationName} {
            0%, 100% {
              transform: translate(${Math.random() * 100}vw, ${Math.random() * 100}vh);
            }
            25% {
              transform: translate(${Math.random() * 100}vw, ${Math.random() * 100}vh);
            }
            50% {
              transform: translate(${Math.random() * 100}vw, ${Math.random() * 100}vh);
            }
            75% {
              transform: translate(${Math.random() * 100}vw, ${Math.random() * 100}vh);
            }
          }
        `;
        document.head.appendChild(style);

        styles.push({
          animation: `${animationName} ${duration}s linear infinite ${delay}s`
        });
      }
      setJellyfishStyles(styles);
    };

    generateJellyfishStyles();

    // Cleanup function to remove styles when component unmounts
    return () => {
      document.head.querySelectorAll('style').forEach(style => {
        if (style.textContent.includes('keyframes swim-')) {
          style.remove();
        }
      });
    };
  }, []);

  return (
    <div className="app-container">
      <div className="jellyfish-container">
        {jellyfishStyles.map((style, index) => (
          <div key={index} className="jellyfish" style={style}></div>
        ))}
      </div>
      <div className="content-container">
        <Header isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
    <h1>
    <span className="gradient-text">Malvi's</span> Archive!
</h1>
        <StatusContainer />
        <Projects />
        <Footer />
      </div>
    </div>
  );
}

export default App;