import React from 'react';

function Header({ isDarkMode, setIsDarkMode }) {
  return (
    <header>
      <div className="theme-switch-wrapper">
        <label className="theme-switch" htmlFor="checkbox">
          <input
            type="checkbox"
            id="checkbox"
            checked={isDarkMode}
            onChange={() => setIsDarkMode(!isDarkMode)}
          />
          <div className="slider round"></div>
        </label>
      </div>
    </header>
  );
}

export default Header;