import React from 'react';

function Footer() {
  return (
    <footer>
      <div className="footer-content">
        <div className="footer-links">
          <a href="https://twitter.com/malvidous">Twitter</a> |
          <a href="https://www.instagram.com/fajar.wh/">Instagram</a> |
          <a href="https://asbun.ubur.my.id/">Blog</a> |
    <a href="#" onClick={(e) => e.preventDefault()}>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    LinkedIn
    </a>
        </div>
        <div className="footer-logo">
          <img src="./img/react-logo.png" alt="React Logo" className="react-logo" />
          <span>React.js</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;