import React, { useState, useEffect } from 'react';

function StatusContainer() {
  const [time, setTime] = useState('');
  const [status, setStatus] = useState({ text: '', dotColor: '' });

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const jakartaTime = new Date(now.getTime() + 420 * 60000); // GMT+7
      const hours = jakartaTime.getUTCHours().toString().padStart(2, '0');
      const minutes = jakartaTime.getUTCMinutes().toString().padStart(2, '0');
      const seconds = jakartaTime.getUTCSeconds().toString().padStart(2, '0');
      setTime(`Jakarta, ${hours}:${minutes}:${seconds} (GMT+7)`);
    };

    const updateStatus = () => {
      const now = new Date();
      const jakartaTime = new Date(now.getTime() + 420 * 60000); // GMT+7
      const hour = jakartaTime.getUTCHours();

      let statusText, dotColor;
      if (hour >= 0 && hour < 4) {
        statusText = 'Sleeping 💤';
        dotColor = 'silver';
      } else if (hour >= 4 && hour < 5) {
        statusText = 'Fajr 🤲🏼';
        dotColor = 'orange';
      } else if (hour >= 5 && hour < 8) {
        statusText = 'Sleeping 💤';
        dotColor = 'orange';
      } else if (hour >= 8 && hour < 12) {
        statusText = 'Busy 👨🏽‍💻';
        dotColor = 'red';
      } else if (hour >= 12 && hour < 13) {
        statusText = 'Resting';
        dotColor = 'green';
      } else if (hour >= 13 && hour < 16) {
        statusText = 'Busy 👨🏽‍💻';
        dotColor = 'red';
      } else if (hour >= 16 && hour < 20) {
        statusText = 'Available 😄🎮';
        dotColor = 'green';
      } else if (hour >= 20 && hour < 22) {
        statusText = 'Busy 📈';
        dotColor = 'red';
      } else {
        statusText = 'Sleeping 💤';
        dotColor = 'orange';
      }

      setStatus({ text: statusText, dotColor: dotColor });
    };

    // Initial updates
    updateTime();
    updateStatus();

    // Set intervals for updates
    const timeInterval = setInterval(updateTime, 1000); // Update every second
    const statusInterval = setInterval(updateStatus, 60000); // Update every minute

    // Cleanup intervals on component unmount
    return () => {
      clearInterval(timeInterval);
      clearInterval(statusInterval);
    };
  }, []);

  return (
    <div className="status-container">
      <div className="status-row">
        <span 
          className="dot" 
          style={{ backgroundColor: status.dotColor }}
        ></span>
        <span id="status-text">I'm currently {status.text}</span>
      </div>
      <div className="time">{time}</div>
    </div>
  );
}

export default StatusContainer;