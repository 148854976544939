import React from 'react';

const projects = [
  {
    img: "img/new_cryptools.png",
    technologies: ["html", "js", "css"],
    alt: "Cryptools",
    title: "Cryptools",
    link: "/cryptools",
    date: "June 15, 2023",
    description: "Cryptools is a robust tool offering security checks on cryptocurrency tokens and market insights on Ethereum and Binance Smart Chain."
  },
  {
    img: "img/new_nostr.png",
    technologies: ["html", "css"], // Add appropriate technologies
    alt: "NOSTR",
    title: "Mybae.id Nostr",
    link: "/nostr",
    date: "August 3, 2023",
    description: "Nostr verified usernames providers, create an easy-to-remember and simplify your online identity on Nostr."
  },
  {
    img: "img/new_etara.png",
    technologies: ["GitHub", "SQL"], // Add appropriate technologies
    alt: "e-TARA",
    title: "e-TARA",
    link: "https://github.com/nescatfe/e-tara",
    date: "September 20, 2023",
    description: "e-TARA is a digital backup for divorce certificates, aiding efficient tracing, report generation, and status checks using case numbers."
  },
  {
    img: "./img/yt_og_thumb.png",
    technologies: ["api", "html", "css", "js"], // Add appropriate technologies
    alt: "Youtube Player",
    title: "Youtube Music",
    link: "/player",
    date: "November 5, 2023",
    description: "YouTube Player utilizing YouTube API for audio-only playback, reducing power consumption while maintaining content accessibility."
  },
  {
    img: "./img/cek-khodam.svg",
    technologies: ["React.js"],
    alt: "Cek Khodam",
    title: "Cek Khodam",
    link: "https://qhodam.vercel.app/",
    date: "June 14, 2024",
    description: "A meme trend site combining interactive humor with modern features for an entertaining experience."
  },
  {
    img: "./img/local-storage.svg",
    technologies: ["React.js"],
    alt: "LocalStorage Note",
    title: "LocalStorage Note",
    link: "https://localnote.vercel.app/",
    date: "18 June, 2024",
    description: "LocalStorage website app built with React.js for managing and storing data locally with ease on your browser."
  },
  {
    img: "./img/fetching-token.svg",
    technologies: ["Node.js", "api"],
    alt: "Fetching Token",
    title: "Fetching Token",
    link: "https://fetcherr.vercel.app/",
    date: "24 June, 2024",
    description: "The Fetching Token app provides real-time data on market cap, volume, price, liquidity, price changes, transactions, pair creations, networks, and offers a Dex Screener link for comprehensive market analysis."
  },
  {
    img: "./img/lifeinweek.svg",
    technologies: ["React.js"],
    alt: "Your Life in Weeks",
    title: "Your Life in Weeks",
    link: "https://lifeinweek.vercel.app/",
    date: "28 June, 2024",
    description: "'Your Life in Weeks' is a React app that visualizes the weeks of a user’s life, helping them track and appreciate the passage of time, week by week."
  }
];

const Projects = () => {
  return (
    <div className="projects">
      {projects.map((project, index) => (
        <div key={index} className="project">
          <img src={project.img} alt={project.alt} />
          <div className="project-info">
        <a href={project.link} target="_blank" rel="noopener noreferrer">
        <h2>{project.title}</h2>
        </a>
            <div className="date">{project.date}</div>
            <p>{project.description}</p>
          </div>
          <div className="tech-tags">
            {project.technologies && project.technologies.map((tech, techIndex) => (
              <span key={techIndex} className="tech-tag">{tech}</span>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Projects;